import * as React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="p-top">
        <a href="#top">
          <img src="img/page_up.png" alt="" width={40} />
        </a>
      </div>
      <div className="footer-box">
        <div className="inner">
          <ul className="footer-nav-1 mb10">
            <li>
              <a href="/">ホーム</a>
            </li>
            <li>
              <ul className="footer-nav-2">
                <li>
                  <a href="area_1.html">三原の泉</a>
                </li>
                <li>
                  <a href="area_2.html">三原の楽園</a>
                </li>
                <li>
                  <a href="area_3.html">王宮</a>
                </li>
                <li>
                  <a href="area_4.html">Bar MIHARA GARDEN</a>
                </li>
                <li>
                  <a href="area_5.html">国王の宝物</a>
                </li>
                <li>
                  <a href="area_6.html">バッキンガム宮殿</a>
                </li>
                <li>
                  <a href="area_7.html">占いの館</a>
                </li>
                <li>
                  <a href="area_8.html">果物畑</a>
                </li>
                <li>
                  <a href="area_9.html">三原ファーム</a>
                </li>
              </ul>
            </li>
            <li>
              <ul className="footer-nav-2">
                <li>
                  <a href="messege.html">国王からのメッセージ</a>
                </li>
                <li>
                  <a href="about.html">ロイヤルミハランドとは</a>
                </li>
                <li>
                  <a href="recruitment.html">国民募集</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="http://www.kaza-hana.jp/">石原和幸デザイン研究所</a>
            </li>
          </ul>
        </div>
      </div>
      <address className="aC t10 white">© ISHIHARA INC.</address>
    </footer>
  );
}
