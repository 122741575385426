import * as React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Amplify, { Auth, Hub, I18n } from "aws-amplify";
import {
  // AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
  AmplifySignOut,
  AmplifyAuthenticator,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "../../aws-exports";
import { Translations } from "@aws-amplify/ui-components";
import ja from "@aws-amplify-jp/vocabulary-ja";
import Footer from "../../components/Footer";

Amplify.configure(awsconfig);
I18n.putVocabulariesForLanguage("ja-JP", ja(Translations));
I18n.setLanguage("ja-JP");

type Props = {
  children: React.ReactNode;
};

const listener = async (data: any) => {
  switch (data.payload.event) {
    case "signIn": {
      console.log("signIn:");
      break;
    }
    case "signUp":
      console.log("user signed up");
      break;
    case "signOut": {
      console.log("user signed out");
      break;
    }
    case "signIn_failure":
      console.log("user sign in failed");
      break;
    case "configured":
      console.log("the Auth module is configured");
  }
};
const signUpFields = [
  {
    type: "email",
    label: "custom_label",
    placeholder: "Custom placeholder",
    hint: null,
    required: true,
  },
];
Hub.listen("auth", listener);

const AuthStateApp = (props: Props) => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();

  const { children } = props;

  React.useEffect(() => {
    // let userSubscribe, reportSubscribe;

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" href="apple-touch-icon.png" />
        <title>ロイヤルミハランド ＜Royal MihaLand＞</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta
          name="description"
          content="ロイヤルミハランドとは、長崎県三原にある『三原庭園』を中心に作られている小さな国であり、街のことを指します。国王は世界的に有名な庭園デザイナー石原和幸のもう一つの姿ではありますが、普段の気鋭の庭園デザイナーである石原先生とは異なり、ちょっとお茶目で面白い性格の持ち主。
      ロイヤルミハランドが今後どのように発展していくのか、どんなストーリーが生まれるのか、国王自らの動画も発信していきますので、ロイヤルミハランドのこれからをお楽しみに！またロイヤルミハランドと共に『三原庭園』へのご来場も心よりお待ちしております！"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&display=swap&subset=japanese"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="/css/destyle.css"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          href="/css/style.css"
          type="text/css"
          media="all"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://mihaland.ishihara28.com/" />
        <meta
          property="og:title"
          content="ロイヤルミハランド＜Royal MihaLand＞"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mihaland.ishihara28.com/" />
        <meta property="og:image" content="img/ogp.jpg" />
        <meta
          property="og:site_name"
          content="ロイヤルミハランド＜Royal MihaLand＞"
        />
        <meta
          property="og:description"
          content="ロイヤルミハランドとは、長崎県三原にある『三原庭園』を中心にした小さな国で、国王は世界的に有名な庭園デザイナー石原和幸のもう一つの姿です。"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="ロイヤルミハランド＜Royal MihaLand＞"
        />
        <meta
          name="twitter:description"
          content="ロイヤルミハランドとは、長崎県三原にある『三原庭園』を中心にした小さな国で、国王は世界的に有名な庭園デザイナー石原和幸のもう一つの姿です。"
        />
        <meta name="twitter:image" content="img/ogp.jpg" />
        <meta itemProp="image" content="img/ogp.jpg" />
        <script
          type="text/javascript"
          src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"
        ></script>
        <script src="/js/jquery.inview.js" type="text/javascript"></script>
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&display=swap&subset=japanese"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />

        <script type="text/javascript" src="js//js/jquery.matchHeight.js" />
      </Helmet>

      <Header />
      {authState === AuthState.SignedIn && user ? (
        <>
          <div
            style={{
              background: "rgba(255,248,242,0.85)",
              paddingTop: 80,
              paddingBottom: 160,
            }}
          >
            {children}
          </div>
          <AmplifySignOut />
        </>
      ) : (
        <AmplifyAuthenticator>
          <AmplifySignUp
            headerText="ミハランド マイページ登録"
            formFields={[
              {
                type: "username",
                label: "ユーザネーム",
                placeholder: "ログイン時に入力が必要となります 例 kazu ",
                required: true,
              },
              {
                type: "email",
                label: "メールアドレス",
                placeholder: "例 kazu@ishihara.co.jp",
                required: true,
              },
              {
                type: "password",
                label: "パスワード",
                placeholder: "半角英数字でパスワードを入力してください。",
                required: true,
              },
            ]}
            slot="sign-up"
          />
          <AmplifySignIn
            headerText="ミハランド マイページログイン"
            formFields={[
              {
                type: "username",
                label: "ユーザネーム",
                placeholder: "登録時に設定したID 例 kazu",
                required: true,
              },
              {
                type: "password",
                label: "パスワード",
                placeholder: "半角英数字でパスワードを入力してください。",
                required: true,
              },
            ]}
            slot="sign-in"
          />
        </AmplifyAuthenticator>
      )}
      <Footer />
    </>
  );
};

export default AuthStateApp;
