import * as React from "react";
import { Auth } from "aws-amplify";

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

async function isLogin() {
  return await Auth.currentAuthenticatedUser()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}

export default function Header() {
  const [login, setLogin] = React.useState(false);
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setLogin(true);
      })
      .catch(() => {
        setLogin(false);
      });
  }, []);

  return (
    <div>
      <header className="head">
        <div className="head-inner clearfix">
          <div className="menuBtn">
            <span />
            <span />
            <span />
          </div>
          <div className="head-logo">
            <h1 className>
              <a href="/">
                <img
                  src="/img/logo_mark.png"
                  alt="ロイヤルミハランド Royal MihaLand"
                  width={180}
                />
              </a>
            </h1>
          </div>
          <div className="head-nav">
            <ul id="gNav">
              <li>
                <a href="/">ホーム</a>
              </li>
              <li className="trigger">
                <p>スポット</p>
                <ul>
                  <li>
                    <a href="/area_1.html">三原の泉</a>
                  </li>
                  <li>
                    <a href="/area_2.html">三原の楽園</a>
                  </li>
                  <li>
                    <a href="/area_3.html">王宮</a>
                  </li>
                  <li>
                    <a href="/area_4.html">Bar MIHARA GARDEN</a>
                  </li>
                  <li>
                    <a href="/area_5.html">国王の宝物</a>
                  </li>
                  <li>
                    <a href="/area_6.html">バッキンガム宮殿</a>
                  </li>
                  <li>
                    <a href="/area_7.html">占いの館</a>
                  </li>
                  <li>
                    <a href="/area_8.html">果物畑</a>
                  </li>
                  <li>
                    <a href="/area_9.html">三原ファーム</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/messege.html">国王からのメッセージ</a>
              </li>
              <li>
                <a href="/about.html">ロイヤルミハランドとは</a>
              </li>
              <li>
                <a href="/recruitment.html">国民募集</a>
              </li>
            </ul>
          </div>
          <div className="head-my">
            <a href="/member">
              <span className="material-icons t10">login</span> マイページ
            </a>
            {/* {login && (
              <p onClick={async () => await signOut()}>
                <span className="material-icons t10">login</span> ログアウト
              </p>
            )} */}
          </div>
        </div>
      </header>
      {/* /header */}
      {/* sp nav */}
      <div className="spNav-wrap">
        <ul className="spNav">
          <li className="spKei">
            <a href="/">ホーム</a>
          </li>
          <li className="spKei">
            <a href="/member">
              <span className="material-icons t10">login</span> マイページ
            </a>
          </li>
          <li className="spKei">
            {/*<p>スポット</p>*/}
            <ul className="spSpot">
              <li>
                <a href="/area_1.html">三原の泉</a>
              </li>
              <li>
                <a href="/area_2.html">三原の楽園</a>
              </li>
              <li>
                <a href="/area_3.html">王宮</a>
              </li>
              <li>
                <a href="/area_4.html">Bar MIHARA GARDEN</a>
              </li>
              <li>
                <a href="/area_5.html">国王の宝物</a>
              </li>
              <li>
                <a href="/area_6.html">バッキンガム宮殿</a>
              </li>
              <li>
                <a href="/area_7.html">占いの館</a>
              </li>
              <li>
                <a href="/area_8.html">果物畑</a>
              </li>
              <li>
                <a href="/area_9.html">三原ファーム</a>
              </li>
            </ul>
          </li>
          <li className="spKei">
            <a href="/messege.html">国王からのメッセージ</a>
          </li>
          <li className="spKei">
            <a href="/about.html">ロイヤルミハランドとは</a>
          </li>
          <li className="spKei" style={{ marginBottom: "60px" }}>
            <a href="/recruitment.html">国民募集</a>
          </li>
        </ul>
      </div>
      {/* sp nav */}
      {/* / navi */}
    </div>
  );
}
